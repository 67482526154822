import React, { useState, useEffect, useRef } from 'react';
import './DialogOverlay.css'; // CSS file for styling

const DialogOverlay = ({ dialogState, lastText, intervention, latencyArray }) => {
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Filter only ACTIVE messages from the dialog state
    const activeMessages = dialogState.filter((message) => message.status === 'ACTIVE');
    setMessages(activeMessages);
  }, [dialogState]);

  useEffect(() => {
    // Scroll to the bottom whenever messages update
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  function formatMilliseconds(ms) {
    if (typeof ms !== 'number' || isNaN(ms)) return 'N/A';
    ms += 1000; // Add 1s because we wait 1s of silence before starting the timer
    const seconds = Math.floor(ms / 1000);
    const milliseconds = Math.round(ms % 1000);
    return `${seconds > 0 ? seconds + 's ' : ''}${milliseconds}ms`;
  }

  return (
    <div className="dialog-overlay">
      {intervention && (
        <div className="intervention-notice">
          <strong>INTERVENTION TRIGGERED</strong>
        </div>
      )}
      {messages.map((message, index) => (
        <div
          key={index}
          className={`dialog-bubble ${message.type === 'AI' || message.type === 'OAI' ? 'ai-bubble' : 'stt-bubble'}`}
        >
          <div className="dialog-header">{message.type === 'AI' || message.type === 'OAI' ? 'Crew is speaking' : 'Listening'}</div>
          <div className="dialog-text">{message.text}</div>
          {
            ((message.type === 'AI' || message.type === 'OAI') && index > 0) && (
              <div className="latency-text">Latency: {formatMilliseconds(latencyArray[index/2-1])}</div>
            )
          }
          
        </div>
      ))}
      {/* This element is used as the target for scrolling */}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default DialogOverlay;
