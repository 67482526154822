import React, { useState, useRef } from 'react';
import './App.css';

// Import images
import LeftImage from './assets/img4_2.png';
import CenterImage from './assets/2.1 (1).png';
import CenterImageActive from './assets/active2.png';
import RightImage from './assets/img3_2.png';
import { nexmoProxy } from './simulator/nexmoProxy.js';
import DialogOverlay from './DialogOverlay';
import OrderSummary from './OrderSummary';
import "./ToggleSwitch.css";
import LatencySummary from './LatencySummary.js'


const Main = () => {
  const [callStatus, setCallStatus] = useState('waiting');
  const [callDuration, setCallDuration] = useState(null);  
  const timerRef = useRef(null);
  const npRef = useRef(null);
  const [dialog, setDialog] = useState([]);
  const [latencyArray, setLatencyArray] = useState([]);
  const [intervention, setIntervention] = useState(false);
  
  const [isStaging, setIsStaging] = useState(false);
  const [fromPhone, setFromPhone] = useState('16503001612');

  const [urlPrefix, setUrlPrefix] = useState(
    "https://voicebite-websocket-app.azurewebsites.net"
    // "http://0.0.0.0:8081"
    // "https://voicebite-websocket-app-staging-epeua4a5dbeme6af.z02.azurefd.net"
  );

  const [cartData, setCartData] = useState([]);

  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [enableWebsocket, setEnableWebsocket] = useState(true);

  // console.log('subtotal:', subtotal);
  // console.log('tax:', tax);
  // console.log('total:', total);

  // console.log('dialog:', dialog);
  // console.log('cartData:', cartData);

  const handleCartData = (obj, latencyArray) => {
    console.log('Cart data received in React:', obj);
    if (obj.data && obj.data.dialog && obj.data.dialog.length > 0) {
      setDialog(obj.data.dialog);
      setLatencyArray(latencyArray);
    }
    if (obj.data && obj.data.dialog && obj.data.dialog.length > 0 && obj.data.dialog.intervention) {
      setIntervention(obj.data.dialog?.intervention);
    }
    if (obj.data && obj.data.json_cart_ocb ) {
      setCartData(obj.data.json_cart_ocb);
    }
    if (obj.data && obj.data.total && obj.data.subtotal && obj.data.tax) {
      setTotal(obj.data.total);
      setSubtotal(obj.data.subtotal);
      setTax(obj.data.tax);
    }
  };
  // console.log('urlPrefix:', urlPrefix);

  const makeCall = async () => {
    setCallDuration('Calling...');
    setCallStatus('active');

    let config = {
      from: fromPhone,
      to: "12029334007",
      urlPrefix: urlPrefix,
      // urlPrefix: "https://jeff-checkmate.ngrok.app",
      staging: isStaging,
      dcPath: "/ws",
      samplingRate: 16000,
      enableWebsocket: enableWebsocket
    };

    try {
      const np = await nexmoProxy.getNexmoProxy(config, handleCartData);
      npRef.current = np; // Store np instance in ref
      console.log('np:', np);

      setCallDuration(0);
      timerRef.current = setInterval(() => {
        setCallDuration(prev => prev + 1);
      }, 1000);
    } catch (error) {
      console.error('Error making the call:', error);
    }
  };

  const handleHangUp = async () => {
    console.log('Hanging up the call...');
    setCallDuration('Call ended');

    // Access the np instance
    if (npRef.current) {
      try {
        await npRef.current.terminate(); // Terminate the call gracefully
        console.log('Call terminated');
      } catch (error) {
        console.error('Error terminating the call:', error);
      }
      npRef.current = null; // Reset the reference
    }
    clearInterval(timerRef.current);
  };

  const handleStopWebSocket = () => {
    const newState = !enableWebsocket;
    setEnableWebsocket(newState);
    // Update WebSocket state in AudioWithAwp instance if it exists
    if (npRef.current && npRef.current.wl) {
      npRef.current.wl.updateWebSocketState(newState);
    }
  }

  const handleRestart = () => {
    console.log('Restarting the call...');
    setCallDuration(0);
    setCallStatus('waiting');
    setDialog([]);
    setCartData([]);
  }

  const toggleEnvironment = () => {
    setIsStaging(!isStaging);
    setUrlPrefix((prevUrl) =>
      prevUrl.includes("staging")
        ? "https://voicebite-websocket-app.azurewebsites.net"
        : "https://voicebite-websocket-app-staging.azurewebsites.net"
    );
  };

  const handleFromPhoneChange = (selectedPhone) => {
    if (selectedPhone === "16503001612") {
      const password = prompt("Enter password:");
      if (password !== "voiceairules") {
        alert("Incorrect password. Access denied.");
        return;
      }
    }
    if (selectedPhone === "257326-lane1") {
      const password = prompt("Enter password:");
      if (password !== "voiceai2025") {
        alert("Incorrect password. Access denied.");
        return;
      }
    }
    setFromPhone(selectedPhone);
  };

  return (
    <div className="App">
      <div className="clouds-container">
        <div className="cloud cloud-1"></div>
        <div className="cloud cloud-2"></div>
        <div className="cloud cloud-3"></div>
        <div className="cloud cloud-4"></div>
        <div className="cloud cloud-5"></div>
        <div className="cloud cloud-6"></div>
      </div>
      <div className="road-lines"></div>
      <div className="scene">
        <div className="menu-board">
          <div className="menu-section">
            <img src={LeftImage} alt="Left Menu" />
          </div>
          <div className="menu-section middle-section" style={{ position: 'relative' }}>
            { callStatus === 'active' ? 
              (<>
                <img src={CenterImageActive} alt="Center Menu" style={{ width: '100%' }} className='border-bottm-none' />
                <div className='flex height-100 border-black'>
                  <DialogOverlay dialogState={dialog} intervention={intervention} latencyArray={latencyArray} />
                  <OrderSummary
                    items={cartData}
                    total={total}
                    subtotal={subtotal}
                    tax={tax}
                  />
                </div>
                
              </>)              
              :
              <img src={CenterImage} alt="Center Menu" />
            }
          </div>
          <div className="menu-section">
            <img src={RightImage} alt="Right Menu" />
          </div>
          <div className="menu-stand"></div>
        </div>
        <div className="call-controls">
          {callStatus === 'waiting' && (
            <button className="call-button" onClick={makeCall}>
              🚗 Drive In
            </button>
          )}
          {(callStatus === 'active' && callDuration === 'Call ended') && (
            <>
              <button className="call-button" onClick={handleRestart}>
                🔴 Restart
              </button>
              {/* <LatencySummary latencyArray={latencyArray} /> */}
            </>
          )}
          {callStatus === 'active' && (
            <>
              <button className="hangup-button" onClick={handleHangUp}>
                🔴 Drive Away
              </button>
              <button className="hangup-button" onClick={handleStopWebSocket}>
                { enableWebsocket ?  "Disable Websocket" : "Enable Websocket" }
              </button>
              <div className="duration">
                {typeof callDuration === 'number' ? `Duration: ${callDuration}s` : callDuration}
              </div>
            </>
          )}

          {callStatus === 'waiting' && (
            <div className='toggle-text-switch'>
              <div className="toggle-container">
                <div
                  className={`toggle-switch ${isStaging ? "staging" : "production"}`}
                  onClick={toggleEnvironment}
                >
                  <div className="toggle-circle"></div>
                </div>
              </div>
              <p>
                Environment: <strong>{isStaging ? "Staging" : "Production"}</strong>
              </p>
            </div>
          )}
        </div>

        {(callStatus === 'active' && callDuration === 'Call ended' && latencyArray.length > 0) && (
          <LatencySummary latencyArray={latencyArray} />
        )}

        {callStatus === "waiting" && (
            <div className="phone-numbers">
              <h4>Select a phone number:</h4>
              <div>
                <label>
                  <input
                    type="radio"
                    name="fromPhone"
                    value="16503001612"
                    checked={fromPhone === "16503001612"}
                    onChange={() => handleFromPhoneChange("16503001612")}
                  />
                  16503001612
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="fromPhone"
                    value="tester-group-1"
                    checked={fromPhone === "tester-group-1"}
                    onChange={() => handleFromPhoneChange("tester-group-1")}
                  />
                  Test Group India
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="fromPhone"
                    value="tester-group-2"
                    checked={fromPhone === "tester-group-2"}
                    onChange={() => handleFromPhoneChange("tester-group-2")}
                  />
                  Test Group North America
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="fromPhone"
                    value="15555555555"
                    checked={fromPhone === "15555555555"}
                    onChange={() => handleFromPhoneChange("15555555555")}
                  />
                  15555555555
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="fromPhone"
                    value="257326-lane1"
                    checked={fromPhone === "257326-lane1"}
                    onChange={() => handleFromPhoneChange("257326-lane1")}
                  />
                  257326-lane1
                </label>
              </div>

              <p>
                Current Phone #: <strong>{fromPhone}</strong>
              </p>
            </div>
          )}
       
        
      </div>
    </div>
  );
};

export default Main;
