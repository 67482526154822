import React, {useState} from 'react';

const LatencySummary = ({ latencyArray }) => {
  const SILENT_DURATION = 1000;
  latencyArray = latencyArray.map(latency => latency + SILENT_DURATION);
  const totalLatency = latencyArray.reduce((sum, latency) => sum + latency, 0)
  const averageLatency = latencyArray.length > 0 ? (totalLatency / latencyArray.length).toFixed(2) : 0;
  const[showingLatencySummary, setShowingLatencySummary] = useState(true);

  const containerStyle = {
    padding: '16px',
    borderRadius: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    border: '1px solid #e5e7eb',
    maxWidth: '400px',
    margin: '20px auto',
    fontFamily: 'Arial, sans-serif',
    right: '1vw',
    position: 'fixed',
  };

  const headingStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '12px',
    color: '#1f2937'
  };

  const textStyle = {
    color: '#4b5563',
    marginBottom: '4px'
  };

  const boldText = {
    fontWeight: 'bold',
    color: '#111827'
  };

  const summaryDivider = {
    marginTop: '16px',
    borderTop: '1px solid #d1d5db',
    paddingTop: '12px'
  };

  function formatMilliseconds(ms) {
    const seconds = Math.floor(ms / 1000);
    const milliseconds = Math.round(ms % 1000);
    return `${seconds > 0 ? seconds + 's ' : ''}${milliseconds}ms`;
  }

  const handleClose = () => {
    setShowingLatencySummary(false);
  }

  return (
    <div style={{display: showingLatencySummary ? 'block' : 'none'}}>
      <div style={containerStyle}>
        <h3 style={headingStyle}>Latency Summary</h3>
        <div>
          {latencyArray.map((latency, index) => (
            <p key={index} style={textStyle}>
              Reply {index + 1}: <span style={boldText}>{formatMilliseconds(latency)}</span>
            </p>
          ))}
        </div>
        <div style={summaryDivider}>
          <p style={textStyle}>Total Latency: <span style={boldText}>{formatMilliseconds(totalLatency)}</span></p>
          <p style={textStyle}>Average Latency: <span style={boldText}>{formatMilliseconds(averageLatency)}</span></p>
        </div>
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default LatencySummary;
